import React from "react";
import WidgetBot from "@widgetbot/react-embed";

const DiscordChat = () => {
  return (
    <div>
      <WidgetBot server="1261557587971805184" channel="1261557587971805187" />
      <h1>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum sed
        itaque quasi perferendis nesciunt delectus sequi culpa exercitationem
        architecto animi, aspernatur, quo excepturi dolores est obcaecati quam
        consequatur. Ratione, voluptas.
      </h1>
    </div>
  );
};

export default DiscordChat;
