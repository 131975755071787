export const series = [
  {
    name: "Amount",
    type: "line",
    data: [
      17423, 14210, 16764, 17879, 13478, 18373, 13891, 12171, 15520, 17269,
      13724, 16707, 18188, 13879, 15626, 13635, 16177, 13156, 18793, 12598,
      18200, 15316, 17552, 11874, 19771, 15038, 16838, 12029, 19793, 16117,
      12879, 17478, 11373, 19891, 15171, 12520, 16269, 19724, 13707, 15188,
      2792, 886, 2472, 1546, 2164, 2323, 2435, 1268, 2368, 2158, 2430, 2300,
      805, 1835, 1684, 2197, 2792, 886, 2472, 1546
    ],
    smooth: true,
    lineStyle: {
      width: 2
    },
    emphasis: {
      focus: "series"
    },
    showSymbol: false
  },
  {
    name: "Addresses",
    type: "line",
    data: [
      1362, 2254, 1962, 1336, 586, 1069, 2194, 1629, 2173, 2031, 1757, 862,
      2446, 910, 2430, 2300, 805, 1835, 1684, 2197, 2792, 886, 2472, 1546, 2164,
      2323, 2435, 1268, 2368, 2158, 2430, 2300, 805, 1835, 1684, 2197, 2792,
      886, 2472, 1546, 18200, 15316, 17552, 11874, 19771, 15038, 16838, 12029,
      19793, 16117, 12879, 17478, 11373, 19891, 15171, 12520, 16269, 19724,
      13707, 15188
    ],
    smooth: true,
    lineStyle: {
      width: 2
    },
    yAxisIndex: 1,
    emphasis: {
      focus: "series"
    },
    showSymbol: false
  }
];
