import TokenSummary from "./TokenSumTable";
import TokensSummary from "./TokensSummary";

const Content = (props) => {
  return (
    <div className="">
      {/* <TokensSummary selectCollection={props.selectCollection} /> */}
      <TokenSummary/>
    </div>
  )
}

export default Content;