const WatchList = () => {

  return (
    <div className="flex items-center justify-center w-full h-full">
      <p className="text-white">WatchList</p>
    </div>
  );
};

export default WatchList;
