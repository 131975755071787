import { Animal } from "../../../common/Icons";

export const LiquidityDataFirst = {
  ItemValue: [
    {
      id: "0",
      like: false,
      address: {
        order: 1,
        avatar: <Animal.Whale />,
        value: "stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
      type: false,
      amountLp: {
        value: 28.20,
        percent: 100
      },
      amountToken: {
        value: 144,
        percent: 100
      },
      amountADA: {
        value: 1643268,
        percent: 100
      },
      value: {
        value: 3286536,
        percent: 100
      },
      walletprofiler: {
        avatar: <Animal.Whale />,
        ownerurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0",
        proflierurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
    },
    {
      id: "1",
      like: false,
      address: {
        order: 2,
        avatar: <Animal.Whale />,
        value: "stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
      type: false,
      amountLp: {
        value: 30.13,
        percent: 80
      },
      amountToken: {
        value: 214,
        percent: 80
      },
      amountADA: {
        value: 126428,
        percent: 30
      },
      value: {
        value: 357216,
        percent: 100
      },
      walletprofiler: {
        avatar: <Animal.Whale />,
        ownerurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0",
        proflierurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
    },
    {
      id: "2",
      like: false,
      address: {
        order: 3,
        avatar: <Animal.Whale />,
        value: "stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
      type: false,
      amountLp: {
        value: 10.13,
        percent: 0
      },
      amountToken: {
        value: 24,
        percent: 10
      },
      amountADA: {
        value: 428,
        percent: 50
      },
      value: {
        value: 216,
        percent: 30
      },
      walletprofiler: {
        avatar: <Animal.Whale />,
        ownerurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0",
        proflierurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
    },
    {
      id: "3",
      like: false,
      address: {
        order: 4,
        avatar: <Animal.Whale />,
        value: "stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
      type: false,
      amountLp: {
        value: 82.13,
        percent: 20
      },
      amountToken: {
        value: 44,
        percent: 20
      },
      amountADA: {
        value: 528,
        percent: 10
      },
      value: {
        value: 116,
        percent: 0
      },
      walletprofiler: {
        avatar: <Animal.Whale />,
        ownerurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0",
        proflierurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
    },
    {
      id: "4",
      like: false,
      address: {
        order: 5,
        avatar: <Animal.SwordFish />,
        value: "stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
      type: false,
      amountLp: {
        value: 80.13,
        percent: 0
      },
      amountToken: {
        value: 64,
        percent: 10
      },
      amountADA: {
        value: 821,
        percent: 50
      },
      value: {
        value: 612,
        percent: 30
      },
      walletprofiler: {
        avatar: <Animal.Whale />,
        ownerurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0",
        proflierurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
    },
    {
      id: "5",
      like: false,
      address: {
        order: 6,
        avatar: <Animal.Whale />,
        value: "stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
      type: false,
      amountLp: {
        value: 28.20,
        percent: 100
      },
      amountToken: {
        value: 144,
        percent: 100
      },
      amountADA: {
        value: 1643268,
        percent: 100
      },
      value: {
        value: 3286536,
        percent: 100
      },
      walletprofiler: {
        avatar: <Animal.Whale />,
        ownerurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0",
        proflierurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
    },
    {
      id: "6",
      like: false,
      address: {
        order: 7,
        avatar: <Animal.Whale />,
        value: "stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
      type: false,
      amountLp: {
        value: 30.13,
        percent: 80
      },
      amountToken: {
        value: 214,
        percent: 80
      },
      amountADA: {
        value: 126428,
        percent: 30
      },
      value: {
        value: 357216,
        percent: 100
      },
      walletprofiler: {
        avatar: <Animal.Whale />,
        ownerurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0",
        proflierurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
    },
    {
      id: "7",
      like: false,
      address: {
        order: 8,
        avatar: <Animal.Whale />,
        value: "stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
      type: false,
      amountLp: {
        value: 10.13,
        percent: 0
      },
      amountToken: {
        value: 24,
        percent: 10
      },
      amountADA: {
        value: 428,
        percent: 50
      },
      value: {
        value: 216,
        percent: 30
      },
      walletprofiler: {
        avatar: <Animal.Whale />,
        ownerurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0",
        proflierurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
    },
    {
      id: "8",
      like: false,
      address: {
        order: 9,
        avatar: <Animal.Whale />,
        value: "stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
      type: false,
      amountLp: {
        value: 82.13,
        percent: 20
      },
      amountToken: {
        value: 44,
        percent: 20
      },
      amountADA: {
        value: 528,
        percent: 10
      },
      value: {
        value: 116,
        percent: 0
      },
      walletprofiler: {
        avatar: <Animal.Whale />,
        ownerurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0",
        proflierurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
    },
    {
      id: "9",
      like: false,
      address: {
        order: 10,
        avatar: <Animal.SwordFish />,
        value: "stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
      type: false,
      amountLp: {
        value: 80.13,
        percent: 0
      },
      amountToken: {
        value: 64,
        percent: 10
      },
      amountADA: {
        value: 821,
        percent: 50
      },
      value: {
        value: 612,
        percent: 30
      },
      walletprofiler: {
        avatar: <Animal.Whale />,
        ownerurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0",
        proflierurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
    },
    {
      id: "11",
      like: false,
      address: {
        order: 12,
        avatar: <Animal.Whale />,
        value: "stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
      type: false,
      amountLp: {
        value: 30.13,
        percent: 80
      },
      amountToken: {
        value: 214,
        percent: 80
      },
      amountADA: {
        value: 126428,
        percent: 30
      },
      value: {
        value: 357216,
        percent: 100
      },
      walletprofiler: {
        avatar: <Animal.Whale />,
        ownerurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0",
        proflierurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
    },
    {
      id: "12",
      like: false,
      address: {
        order: 13,
        avatar: <Animal.Whale />,
        value: "stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
      type: false,
      amountLp: {
        value: 10.13,
        percent: 0
      },
      amountToken: {
        value: 24,
        percent: 10
      },
      amountADA: {
        value: 428,
        percent: 50
      },
      value: {
        value: 216,
        percent: 30
      },
      walletprofiler: {
        avatar: <Animal.Whale />,
        ownerurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0",
        proflierurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
    },
    {
      id: "13",
      like: false,
      address: {
        order: 14,
        avatar: <Animal.Whale />,
        value: "stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
      type: false,
      amountLp: {
        value: 82.13,
        percent: 20
      },
      amountToken: {
        value: 44,
        percent: 20
      },
      amountADA: {
        value: 528,
        percent: 10
      },
      value: {
        value: 116,
        percent: 0
      },
      walletprofiler: {
        avatar: <Animal.Whale />,
        ownerurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0",
        proflierurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
    },
    {
      id: "14",
      like: false,
      address: {
        order: 15,
        avatar: <Animal.SwordFish />,
        value: "stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
      type: false,
      amountLp: {
        value: 80.13,
        percent: 0
      },
      amountToken: {
        value: 64,
        percent: 10
      },
      amountADA: {
        value: 821,
        percent: 50
      },
      value: {
        value: 612,
        percent: 30
      },
      walletprofiler: {
        avatar: <Animal.Whale />,
        ownerurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0",
        proflierurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
    },
  ],
  maxswapamount: 36326,
  totaldatanumber: 743,
};
