import * as IMG from "../../common/IMG/Images"

const Price3 = () => {
  return (
    <div className="flex w-8 h-8 items-center">
    <img
      src={IMG.logo3}
      height={30}
      width={30}
      className=""
      alt="logo" />
  </div>
  )
}

export default Price3;