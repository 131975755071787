import PortfolioChartContent from "./PortfolioChartContent";
const PortfolioChart = () => {
  return (
    <div className="flex flex-col w-full">
      <PortfolioChartContent />
    </div>

  );
};

export default PortfolioChart;
