import './loader.css'

const SignalLoader = () => {
  
  return (
<div class="loader-signal">
    <span></span>
</div>
  )
}

export default SignalLoader;