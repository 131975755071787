export const sideBarData1 = [
  {
    img:"https://www.riskwises.io/_next/image?url=https%3A%2F%2Friskwises-public.s3.amazonaws.com%2Ftoken-logos%2Ff43a62fdc3965df486de8a0d32fe800963589c41b38946602a0dc53541474958.png&w=32&q=75",
    name:"AGIX",
    holdings:315738
  },
  {
    img:"https://www.riskwises.io/_next/image?url=https%3A%2F%2Friskwises-public.s3.amazonaws.com%2Ftoken-logos%2F5d16cc1a177b5d9ba9cfa9793b07e60f1fb70fea1f8aef064415d114494147.png&w=32&q=75",
    name:"IAG",
    holdings:175711
  },
  {
    img:"https://www.riskwises.io/_next/image?url=https%3A%2F%2Friskwises-public.s3.amazonaws.com%2Ftoken-logos%2F8db269c3ec630e06ae29f74bc39edd1f87c819f1056206e879a1cd615368656e4d6963726f555344.png&w=32&q=75",
    name:"SHEN",
    holdings:129612
  },
  {
    img:"https://www.riskwises.io/_next/image?url=https%3A%2F%2Friskwises-public.s3.amazonaws.com%2Ftoken-logos%2Fedfd7a1d77bcb8b884c474bdc92a16002d1fb720e454fa6e993444794e5458.png&w=32&q=75",
    name:"NTX",
    holdings:101853
  },
  {
    img:"https://www.riskwises.io/_next/image?url=https%3A%2F%2Friskwises-public.s3.amazonaws.com%2Ftoken-logos%2F1d7f33bd23d85e1a25d87d86fac4f199c3197a2f7afeb662a0f34e1e776f726c646d6f62696c65746f6b656e.png&w=32&q=75",
    name:"WMT",
    holdings:101827
  },
  {
    img:"https://www.riskwises.io/_next/image?url=https%3A%2F%2Friskwises-public.s3.amazonaws.com%2Ftoken-logos%2F6ac8ef33b510ec004fe11585f7c5a9f0c07f0c23428ab4f29c1d7d104d454c44.png&w=32&q=75",
    name:"MELD",
    holdings:49845
  },
  {
    img:"https://www.riskwises.io/_next/image?url=https%3A%2F%2Friskwises-public.s3.amazonaws.com%2Ftoken-logos%2F8a1cfae21368b8bebbbed9800fec304e95cce39a2a57dc35e2e3ebaa4d494c4b.png&w=32&q=75",
    name:"MILK",
    holdings:37972
  },
  {
    img:"https://www.riskwises.io/_next/image?url=https%3A%2F%2Friskwises-public.s3.amazonaws.com%2Ftoken-logos%2Fb6a7467ea1deb012808ef4e87b5ff371e85f7142d7b356a40d9b42a0436f726e75636f70696173205b76696120436861696e506f72742e696f5d.png&w=32&q=75",
    name:"COPI",
    holdings:33552
  },
  {

    img:"https://www.riskwises.io/_next/image?url=https%3A%2F%2Friskwises-public.s3.amazonaws.com%2Fsrc%2Ftoken-logos%2F7914fae20eb2903ed6fd5021a415c1bd2626b64a2d86a304cb40ff5e4c494649.png&w=32&q=75",
    name:"LIFI",
    holdings:30110
  },
  {
    img:"https://www.riskwises.io/_next/image?url=https%3A%2F%2Friskwises-public.s3.amazonaws.com%2Fsrc%2Ftoken-logos%2F09f2d4e4a5c3662f4c1e6a7d9600e9605279dbdcedb22d4507cb6e75535046.png&w=32&q=75",
    name:"SPF",
    holdings:26849
  }
]

export const sideBarData2 = [
  {
    img:"https://www.riskwises.io/_next/image?url=https%3A%2F%2Friskwises-public.s3.amazonaws.com%2Ftoken-logos%2Ff43a62fdc3965df486de8a0d32fe800963589c41b38946602a0dc53541474958.png&w=32&q=75",
    name:"AGIX",
    holdings:315738
  },
  {
    img:"https://www.riskwises.io/_next/image?url=https%3A%2F%2Friskwises-public.s3.amazonaws.com%2Ftoken-logos%2F5d16cc1a177b5d9ba9cfa9793b07e60f1fb70fea1f8aef064415d114494147.png&w=32&q=75",
    name:"IAG",
    holdings:175711
  },
  {
    img:"https://www.riskwises.io/_next/image?url=https%3A%2F%2Friskwises-public.s3.amazonaws.com%2Ftoken-logos%2F8db269c3ec630e06ae29f74bc39edd1f87c819f1056206e879a1cd615368656e4d6963726f555344.png&w=32&q=75",
    name:"SHEN",
    holdings:129612
  },
  {
    img:"https://www.riskwises.io/_next/image?url=https%3A%2F%2Friskwises-public.s3.amazonaws.com%2Ftoken-logos%2Fedfd7a1d77bcb8b884c474bdc92a16002d1fb720e454fa6e993444794e5458.png&w=32&q=75",
    name:"NTX",
    holdings:101853
  },
  {
    img:"https://www.riskwises.io/_next/image?url=https%3A%2F%2Friskwises-public.s3.amazonaws.com%2Ftoken-logos%2F1d7f33bd23d85e1a25d87d86fac4f199c3197a2f7afeb662a0f34e1e776f726c646d6f62696c65746f6b656e.png&w=32&q=75",
    name:"WMT",
    holdings:101827
  },
  {
    img:"https://www.riskwises.io/_next/image?url=https%3A%2F%2Friskwises-public.s3.amazonaws.com%2Ftoken-logos%2F6ac8ef33b510ec004fe11585f7c5a9f0c07f0c23428ab4f29c1d7d104d454c44.png&w=32&q=75",
    name:"MELD",
    holdings:49845
  },
  {
    img:"https://www.riskwises.io/_next/image?url=https%3A%2F%2Friskwises-public.s3.amazonaws.com%2Ftoken-logos%2F8a1cfae21368b8bebbbed9800fec304e95cce39a2a57dc35e2e3ebaa4d494c4b.png&w=32&q=75",
    name:"MILK",
    holdings:37972
  },
  {
    img:"https://www.riskwises.io/_next/image?url=https%3A%2F%2Friskwises-public.s3.amazonaws.com%2Ftoken-logos%2Fb6a7467ea1deb012808ef4e87b5ff371e85f7142d7b356a40d9b42a0436f726e75636f70696173205b76696120436861696e506f72742e696f5d.png&w=32&q=75",
    name:"COPI",
    holdings:33552
  },
  {

    img:"https://www.riskwises.io/_next/image?url=https%3A%2F%2Friskwises-public.s3.amazonaws.com%2Fsrc%2Ftoken-logos%2F7914fae20eb2903ed6fd5021a415c1bd2626b64a2d86a304cb40ff5e4c494649.png&w=32&q=75",
    name:"LIFI",
    holdings:30110
  },
  {
    img:"https://www.riskwises.io/_next/image?url=https%3A%2F%2Friskwises-public.s3.amazonaws.com%2Fsrc%2Ftoken-logos%2F09f2d4e4a5c3662f4c1e6a7d9600e9605279dbdcedb22d4507cb6e75535046.png&w=32&q=75",
    name:"SPF",
    holdings:26849
  }
]

export const sideBarData3 = [
  {
    img:"https://www.riskwises.io/_next/image?url=https%3A%2F%2Friskwises-public.s3.amazonaws.com%2Fexchange-logos%2Fminswap.png&w=32&q=75",
    name:"AGIX",
    holdings:315738
  },
  {
    img:"https://www.riskwises.io/_next/image?url=https%3A%2F%2Friskwises-public.s3.amazonaws.com%2Fexchange-logos%2Fvyfi.png&w=32&q=75",
    name:"IAG",
    holdings:175711
  },
  {
    img:"https://www.riskwises.io/_next/image?url=https%3A%2F%2Friskwises-public.s3.amazonaws.com%2Fexchange-logos%2Fsundaeswap.png&w=32&q=75",
    name:"SHEN",
    holdings:129612
  },
  {
    img:"https://www.riskwises.io/_next/image?url=https%3A%2F%2Friskwises-public.s3.amazonaws.com%2Fexchange-logos%2Fwingriders.png&w=32&q=75",
    name:"NTX",
    holdings:101853
  },
  {
    img:"https://www.riskwises.io/_next/image?url=https%3A%2F%2Friskwises-public.s3.amazonaws.com%2Fexchange-logos%2Fspectrumfi.png&w=32&q=75",
    name:"WMT",
    holdings:101827
  }
]