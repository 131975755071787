import { Animal } from "../../../common/Icons";
import { Minswap, Vyfi } from "../../../common/IMG/Images";
export const LiquidityDataThird = {
  ItemValue: [
    {
      id: "0",
      like: false,
      address: {
        avatar: <Animal.Whale />,
        value: "stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
      type: false,
      time: 18,
      tokenPrice: 0.001221,
      Type: "Add",
      totalSNEK: {
        value: 28.20,
        percent: 100
      },
      totalUSD: {
        value: 144,
        percent: 100
      },
      exchange: {
        avatar: Minswap,
        name: "SundaeSwap"
      },
      walletprofiler: {
        avatar: <Animal.Whale />,
        ownerurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0",
        proflierurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
    },
    {
      id: "1",
      like: false,
      address: {
        avatar: <Animal.Whale />,
        value: "stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
      type: false,
      time: 20,
      tokenPrice: 0.00112,
      Type: "Remove",
      totalSNEK: {
        value: 111,
        percent: 0
      },
      totalUSD: {
        value: 34,
        percent: 20
      },
      exchange: {
        avatar: Minswap,
        name: "SundaeSwap"
      },
      walletprofiler: {
        avatar: <Animal.Whale />,
        ownerurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0",
        proflierurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
    },
    {
      id: "2",
      like: false,
      address: {
        avatar: <Animal.SwordFish />,
        value: "stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
      type: false,
      time: 48,
      tokenPrice: 11.21,
      Type: "Zap",
      totalSNEK: {
        value: 213,
        percent: 30
      },
      totalUSD: {
        value: 321,
        percent: 10
      },
      exchange: {
        avatar: Minswap,
        name: "SundaeSwap"
      },
      walletprofiler: {
        avatar: <Animal.Whale />,
        ownerurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0",
        proflierurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
    },
    {
      id: "3",
      like: false,
      address: {
        avatar: <Animal.Whale />,
        value: "stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
      type: false,
      time: 50,
      tokenPrice: 21.122,
      Type: "Remove",
      totalSNEK: {
        value: 333,
        percent: 30
      },
      totalUSD: {
        value: 14,
        percent: 30
      },
      exchange: {
        avatar: Vyfi,
        name: "VyFinance"
      },
      walletprofiler: {
        avatar: <Animal.Whale />,
        ownerurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0",
        proflierurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
    },
    {
      id: "4",
      like: false,
      address: {
        avatar: <Animal.Whale />,
        value: "stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
      type: false,
      time: 20,
      tokenPrice: 3121,
      Type: "Remove",
      totalSNEK: {
        value: 13,
        percent: 10
      },
      totalUSD: {
        value: 140,
        percent: 50
      },
      exchange: {
        avatar: Vyfi,
        name: "VyFinance"
      },
      walletprofiler: {
        avatar: <Animal.Whale />,
        ownerurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0",
        proflierurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
    },
    {
      id: "5",
      like: false,
      address: {
        avatar: <Animal.Whale />,
        value: "stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
      type: false,
      time: 18,
      tokenPrice: 0.001221,
      Type: "Add",
      totalSNEK: {
        value: 28.20,
        percent: 100
      },
      totalUSD: {
        value: 144,
        percent: 100
      },
      exchange: {
        avatar: Minswap,
        name: "SundaeSwap"
      },
      walletprofiler: {
        avatar: <Animal.Whale />,
        ownerurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0",
        proflierurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
    },
    {
      id: "6",
      like: false,
      address: {
        avatar: <Animal.Whale />,
        value: "stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
      type: false,
      time: 20,
      tokenPrice: 0.00112,
      Type: "Remove",
      totalSNEK: {
        value: 111,
        percent: 0
      },
      totalUSD: {
        value: 34,
        percent: 20
      },
      exchange: {
        avatar: Minswap,
        name: "SundaeSwap"
      },
      walletprofiler: {
        avatar: <Animal.Whale />,
        ownerurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0",
        proflierurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
    },
    {
      id: "7",
      like: false,
      address: {
        avatar: <Animal.SwordFish />,
        value: "stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
      type: false,
      time: 48,
      tokenPrice: 11.21,
      Type: "Zap",
      totalSNEK: {
        value: 213,
        percent: 30
      },
      totalUSD: {
        value: 321,
        percent: 10
      },
      exchange: {
        avatar: Minswap,
        name: "SundaeSwap"
      },
      walletprofiler: {
        avatar: <Animal.Whale />,
        ownerurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0",
        proflierurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
    },
    {
      id: "8",
      like: false,
      address: {
        avatar: <Animal.Whale />,
        value: "stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
      type: false,
      time: 50,
      tokenPrice: 21.122,
      Type: "Remove",
      totalSNEK: {
        value: 333,
        percent: 30
      },
      totalUSD: {
        value: 14,
        percent: 30
      },
      exchange: {
        avatar: Vyfi,
        name: "VyFinance"
      },
      walletprofiler: {
        avatar: <Animal.Whale />,
        ownerurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0",
        proflierurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
    },
    {
      id: "9",
      like: false,
      address: {
        avatar: <Animal.Whale />,
        value: "stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
      type: false,
      time: 20,
      tokenPrice: 3121,
      Type: "Remove",
      totalSNEK: {
        value: 13,
        percent: 10
      },
      totalUSD: {
        value: 140,
        percent: 50
      },
      exchange: {
        avatar: Vyfi,
        name: "VyFinance"
      },
      walletprofiler: {
        avatar: <Animal.Whale />,
        ownerurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0",
        proflierurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
    },
    {
      id: "10",
      like: false,
      address: {
        avatar: <Animal.Whale />,
        value: "stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
      type: false,
      time: 18,
      tokenPrice: 0.001221,
      Type: "Add",
      totalSNEK: {
        value: 28.20,
        percent: 100
      },
      totalUSD: {
        value: 144,
        percent: 100
      },
      exchange: {
        avatar: Minswap,
        name: "SundaeSwap"
      },
      walletprofiler: {
        avatar: <Animal.Whale />,
        ownerurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0",
        proflierurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
    },
    {
      id: "11",
      like: false,
      address: {
        avatar: <Animal.Whale />,
        value: "stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
      type: false,
      time: 20,
      tokenPrice: 0.00112,
      Type: "Remove",
      totalSNEK: {
        value: 111,
        percent: 0
      },
      totalUSD: {
        value: 34,
        percent: 20
      },
      exchange: {
        avatar: Minswap,
        name: "SundaeSwap"
      },
      walletprofiler: {
        avatar: <Animal.Whale />,
        ownerurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0",
        proflierurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
    },
    {
      id: "12",
      like: false,
      address: {
        avatar: <Animal.SwordFish />,
        value: "stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
      type: false,
      time: 48,
      tokenPrice: 11.21,
      Type: "Zap",
      totalSNEK: {
        value: 213,
        percent: 30
      },
      totalUSD: {
        value: 321,
        percent: 10
      },
      exchange: {
        avatar: Minswap,
        name: "SundaeSwap"
      },
      walletprofiler: {
        avatar: <Animal.Whale />,
        ownerurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0",
        proflierurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
    },
    {
      id: "13",
      like: false,
      address: {
        avatar: <Animal.Whale />,
        value: "stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
      type: false,
      time: 50,
      tokenPrice: 21.122,
      Type: "Remove",
      totalSNEK: {
        value: 333,
        percent: 30
      },
      totalUSD: {
        value: 14,
        percent: 30
      },
      exchange: {
        avatar: Vyfi,
        name: "VyFinance"
      },
      walletprofiler: {
        avatar: <Animal.Whale />,
        ownerurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0",
        proflierurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
    },
    {
      id: "14",
      like: false,
      address: {
        avatar: <Animal.Whale />,
        value: "stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
      type: false,
      time: 20,
      tokenPrice: 3121,
      Type: "Remove",
      totalSNEK: {
        value: 13,
        percent: 10
      },
      totalUSD: {
        value: 140,
        percent: 50
      },
      exchange: {
        avatar: Vyfi,
        name: "VyFinance"
      },
      walletprofiler: {
        avatar: <Animal.Whale />,
        ownerurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0",
        proflierurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
    },
  ],
  maxswapamount: 36326,
  totaldatanumber: 743,
};
