import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import MoonLoader from "react-spinners/MoonLoader";


const PortfolioContentPart3Footer = ({portfolioType}) => {
  const [isActive, setIsActive] = useState(true);
  const [isTokenActive, setIsToeknActive] = useState(true)

  const profilerData = useSelector((state) => state.walletProfilerReducer.walletProfilerBalance);
  const portData = useSelector((state)=> state.wallet.walletPosition)

  const [walletProfilerBalance, setWalletProfilerBalance] = useState([])

 useEffect(()=>{
   if(portfolioType === 'portfolio'){
    setWalletProfilerBalance(portData)
   }else{
    setWalletProfilerBalance(profilerData)
   }
 },[portData, portfolioType, profilerData])
 
  const ContentData1 = [
    {
      id: 0,
      value: "Net Worth ADA",
      price: walletProfilerBalance?.walletPosition ?  `${(walletProfilerBalance?.walletPosition?.adaValue?.toFixed())}₳` : '0'
    },
    {
      id: 1,
      value: "Assets",
      price: walletProfilerBalance?.walletPosition ?  `${(walletProfilerBalance?.walletPosition?.adaValue?.toFixed())}₳` : '0'
    },
    {
      id: 2,
      value: "Liabilities",
      price: "0₳"
    },
    {
      id: 3,
      value: "CollateralRatio",
      price: "%"
    },
  ]
  const ContentData2 = [
    {
      id: 0,
      value: "ADA Balance",
      price:  walletProfilerBalance?.walletPosition ? `${(walletProfilerBalance?.walletPosition?.adaBalance?.toFixed())}₳` : '0'
    },
    {
      id: 1,
      value: "Native Assets Value",
      price: walletProfilerBalance?.walletPosition ? `${((walletProfilerBalance?.walletPosition?.adaValue - walletProfilerBalance?.walletPosition?.adaBalance)?.toFixed())}₳` :'0'
    },
    {
      id: 2,
      value: "NA % of Portfolio",
      price: "0.00%"
    },
    {  
      id: 3,
      value: "Liquid Value",
      price:  walletProfilerBalance?.walletPosition ? `${(walletProfilerBalance?.walletPosition?.liquidValue?.toFixed())}₳` : '0',
    },
    {
      id: 4,
      value: "Tokens",
      price: walletProfilerBalance?.walletPosition ?  `${walletProfilerBalance?.walletPosition?.numFTs}` : '0'
    },
    {
      id: 5,
      value: "NFTs",
      price: walletProfilerBalance?.walletPosition ?  `${walletProfilerBalance?.walletPosition?.numNFTs}` : '0'
    },
  ]

  return (
    <div
      style={{
        // "background": "transparent linear-gradient(0deg,#142028,#121212) 0 0 no-repeat padding-box"


      }}
      className="flex flex-col gap-2 h-fit bg-yellow-500 rounded-xl">
      {/* Header */}
      <div className="flex h-[85px] rounded-xl justify-around  gap-3 p-3">
        <div className="flex justify-start w-full">
          <div className="flex items-center gap-4  rounded-lg w-full p-3">
            <div
              onClick={() => setIsActive(true)}
              className={`flex justify-center items-center cursor-pointer  px-3 py-2  rounded-lg ${isActive ? "bg-[#121218] text-white" : "bg-[#121218]"}`}>
              <p className={` text-sm font-normal w-full ${isActive ? "text-white" : "text-white"}`}>BreakDown</p>
            </div>
            {/* <div
              onClick={() => setIsActive(false)}
              className={`flex justify-center items-center cursor-pointer  px-3 py-2 rounded-lg ${isActive ? "" : "bg-[#3a4956]"}`}>
              <p className={`text-sm font-normal ${isActive ? "text-white" : "text-white "}`}>Asset Performance</p>
            </div> */}
          </div>
        </div>
      </div>
      {/* Border */}
      <div
        className="flex h-[45px] rounded-2xl justify-around  gap-3 p-3">
        <div className="flex justify-start w-full">
          <div className="flex items-center gap-4 bg-[#0b1217] rounded-lg w-full p-3">
          </div>
        </div>
      </div>
      {/* Content1 && Content2*/}
      {isActive ?
        (<>
          <div
            className="h-fit rounded-xl justify-around    gap-1 p-3">
            <div className="flex flex-col bg-[#0b1217] rounded-xl pt-2">
              {ContentData1?.map((item, idx) => {
                return (
                  <div
                    key={idx}
                    className="flex justify-between items-center gap-4  px-3 ">
                    <div
                      className={`flex justify-center items-center w-full cursor-pointer  px-3  gap-10`}>
                      <p className="text-[#9f9fa8] text-sm font-normal whitespace-nowrap">{item.value}</p>
                      <div className="bg-[#24242c] h-1 w-full rounded-lg"></div>
                      <p className="text-[#9f9fa8] text-sm font-normal tracking-wider">{item.price}</p>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
          <div
            className="h-fit rounded-xl justify-around    gap-1 p-3">
            <div className="flex flex-col bg-[#0b1217] rounded-xl pt-3">
              {ContentData2.map((item, idx) => {
                return (
                  <div className="flex justify-between items-center gap-4  px-3 ">
                    <div
                      className={`flex justify-center items-center w-full cursor-pointer  px-3 gap-10`}>
                      <p className="text-[#9f9fa8] text-sm font-normal whitespace-nowrap  ">{item?.value}</p>
                      <div className="bg-[#24242c] h-[3px] w-full rounded-lg"></div>
                      <p className="text-[#9f9fa8] text-sm font-normal tracking-wider">{item?.price}</p>
                    </div>
                  </div>
                )
              })}

            </div>
          </div>
        </>) :
        (<div className="flex flex-col gap-3">
          <div className="flex justify-end px-3 gap-6">
            <button
              onClick={() => setIsToeknActive(true)}
              className={`text-white hover:text-white text-base font-normal ${isTokenActive ? "bg-[#3a4956] rounded-lg px-2 py-[2px] text-white" : "text-black"}`}>Tokens</button>
            <button
              onClick={() => setIsToeknActive(false)}
              className={`text-white hover:text-white text-base font-normal ${isTokenActive ? "text-black" : "bg-[#3a4956] rounded-lg px-2 py-[2px] text-white"}`}>NFTs</button>
          </div>
          <div className="px-3  h-[300px] w-full mb-4">
            <div className="flex justify-center items-center  w-full h-full bg-[#0b1217] rounded-2xl">
              <MoonLoader
                size={20}
                speedMultiplier={0.3}
                color="yellow" />
            </div>
          </div>
        </div>

        )}

    </div>
  );
};

export default PortfolioContentPart3Footer;
