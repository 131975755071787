
const Bubbles = () => {

    return (
        <>
            <div className="w-full">
                <iframe src="https://cryptobubbles.net/" height="900rem" width="100%" title="uniswap"></iframe> 
            </div>
        </>
    )
}

export default Bubbles;