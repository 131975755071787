import { Animal } from "../../../common/Icons";

export const TransferDatasTwo = {
  ItemValue: [
    {
      id: "0",
      time: 2,
      Fromaddress: {
        avatar: <Animal.Whale />,
        value: "stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
      Toaddress: {
        avatar: <Animal.SwordFish />,
        value: "adfadivkjduafueiquefajdshfuqeoiruaydfwegrasdfgaguetrif"
      },
      typeFrom: false,
      typeTo: false,
      tokenAmount: {
        vale: 244332,
        percent: 20,
      },
      value: {
        value: 50490,
        percent: 20,
      },
      walletprofiler: {
        avatar: <Animal.Whale />,
        ownerurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0",
        proflierurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
    },
    {
      id: "1",
      time: 2,
      Fromaddress: {
        avatar: <Animal.Whale />,
        value: "stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
      Toaddress: {
        avatar: <Animal.SwordFish/>,
        value: "adfadivkjduafueiquefajdshfuqeoiruaydfwegrasdfgaguetrif"
      },
      typeFrom: false,
      typeTo: false,
      tokenAmount: {
        vale: 212341,
        percent: 10,
      },
      value: {
        value: 1234112,
        percent: 60,
      },
      walletprofiler: {
        avatar: <Animal.Whale />,
        ownerurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0",
        proflierurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
    },
    {
      id: "2",
      time: 21,
      Fromaddress: {
        avatar: <Animal.SwordFish />,
        value: "stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
      Toaddress: {
        avatar: <Animal.SwordFish />,
        value: "adfadivkjduafueiquefajdshfuqeoiruaydfwegrasdfgaguetrif"
      },
      typeFrom: false,
      typeTo: false,
      tokenAmount: {
        vale: 23333,
        percent: 10,
      },
      value: {
        value: 4401231,
        percent: 20,
      },
      walletprofiler: {
        avatar: <Animal.Whale />,
        ownerurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0",
        proflierurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
    },
    {
      id: "3",
      time: 2,
      Fromaddress: {
        avatar: <Animal.Whale />,
        value: "stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
      Toaddress: {
        avatar: <Animal.Whale />,
        value: "adfadivkjduafueiquefajdshfuqeoiruaydfwegrasdfgaguetrif"
      },
      typeFrom: false,
      typeTo: false,
      tokenAmount: {
        vale: 988291,
        percent: 40,
      },
      value: {
        value: 4040,
        percent: 40,
      },
      walletprofiler: {
        avatar: <Animal.Whale />,
        ownerurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0",
        proflierurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
    },
    {
      id: "4",
      time: 21,
      Fromaddress: {
        avatar: <Animal.Whale />,
        value: "stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
      Toaddress: {
        avatar: <Animal.SwordFish />,
        value: "adfadivkjduafueiquefajdshfuqeoiruaydfwegrasdfgaguetrif"
      },
      typeFrom: false,
      typeTo: false,
      tokenAmount: {
        vale: 992019,
        percent: 10,
      },
      value: {
        value: 292819,
        percent: 40,
      },
      walletprofiler: {
        avatar: <Animal.Whale />,
        ownerurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0",
        proflierurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
    },
    {
      id: "5",
      time: 11,
      Fromaddress: {
        avatar: <Animal.Whale />,
        value: "stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
      Toaddress: {
        avatar: <Animal.SwordFish />,
        value: "adfadivkjduafueiquefajdshfuqeoiruaydfwegrasdfgaguetrif"
      },
      typeFrom: false,
      typeTo: false,
      tokenAmount: {
        vale: 98212,
        percent: 50,
      },
      value: {
        value: 66190,
        percent: 40,
      },
      walletprofiler: {
        avatar: <Animal.Whale />,
        ownerurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0",
        proflierurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
    },
    {
      id: "6",
      time: 7,
      Fromaddress: {
        avatar: <Animal.Whale />,
        value: "stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
      Toaddress: {
        avatar: <Animal.SwordFish />,
        value: "adfadivkjduafueiquefajdshfuqeoiruaydfwegrasdfgaguetrif"
      },
      typeFrom: false,
      typeTo: false,
      tokenAmount: {
        vale: 19921,
        percent: 70,
      },
      value: {
        value: 54290,
        percent: 84,
      },
      walletprofiler: {
        avatar: <Animal.Whale />,
        ownerurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0",
        proflierurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
    },
    {
      id: "7",
      time: 14,
      Fromaddress: {
        avatar: <Animal.Whale />,
        value: "stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
      Toaddress: {
        avatar: <Animal.SwordFish />,
        value: "adfadivkjduafueiquefajdshfuqeoiruaydfwegrasdfgaguetrif"
      },
      typeFrom: false,
      typeTo: false,
      tokenAmount: {
        vale: 1332,
        percent: 40,
      },
      value: {
        value: 40190,
        percent: 20,
      },
      walletprofiler: {
        avatar: <Animal.Whale />,
        ownerurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0",
        proflierurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
    },
    {
      id: "8",
      time: 7,
      Fromaddress: {
        avatar: <Animal.Whale />,
        value: "stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
      Toaddress: {
        avatar: <Animal.SwordFish />,
        value: "adfadivkjduafueiquefajdshfuqeoiruaydfwegrasdfgaguetrif"
      },
      typeFrom: false,
      typeTo: false,
      tokenAmount: {
        vale: 88817,
        percent: 10,
      },
      value: {
        value: 3392,
        percent: 13,
      },
      walletprofiler: {
        avatar: <Animal.Whale />,
        ownerurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0",
        proflierurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
    },
    {
      id: "9",
      time: 17,
      Fromaddress: {
        avatar: <Animal.Whale />,
        value: "stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
      Toaddress: {
        avatar: <Animal.SwordFish />,
        value: "adfadivkjduafueiquefajdshfuqeoiruaydfwegrasdfgaguetrif"
      },
      typeFrom: false,
      typeTo: false,
      tokenAmount: {
        vale: 22947,
        percent: 60,
      },
      value: {
        value: 39182,
        percent: 50,
      },
      walletprofiler: {
        avatar: <Animal.Whale />,
        ownerurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0",
        proflierurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
    },
    {
      id: "10",
      time: 2,
      Fromaddress: {
        avatar: <Animal.Whale />,
        value: "stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
      Toaddress: {
        avatar: <Animal.SwordFish />,
        value: "adfadivkjduafueiquefajdshfuqeoiruaydfwegrasdfgaguetrif"
      },
      typeFrom: false,
      typeTo: false,
      tokenAmount: {
        vale: 244332,
        percent: 20,
      },
      value: {
        value: 50490,
        percent: 20,
      },
      walletprofiler: {
        avatar: <Animal.Whale />,
        ownerurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0",
        proflierurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
    },
    {
      id: "11",
      time: 2,
      Fromaddress: {
        avatar: <Animal.Whale />,
        value: "stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
      Toaddress: {
        avatar: <Animal.SwordFish/>,
        value: "adfadivkjduafueiquefajdshfuqeoiruaydfwegrasdfgaguetrif"
      },
      typeFrom: false,
      typeTo: false,
      tokenAmount: {
        vale: 212341,
        percent: 10,
      },
      value: {
        value: 1234112,
        percent: 60,
      },
      walletprofiler: {
        avatar: <Animal.Whale />,
        ownerurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0",
        proflierurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
    },
    {
      id: "12",
      time: 21,
      Fromaddress: {
        avatar: <Animal.SwordFish />,
        value: "stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
      Toaddress: {
        avatar: <Animal.SwordFish />,
        value: "adfadivkjduafueiquefajdshfuqeoiruaydfwegrasdfgaguetrif"
      },
      typeFrom: false,
      typeTo: false,
      tokenAmount: {
        vale: 23333,
        percent: 10,
      },
      value: {
        value: 4401231,
        percent: 20,
      },
      walletprofiler: {
        avatar: <Animal.Whale />,
        ownerurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0",
        proflierurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
    },
    {
      id: "13",
      time: 2,
      Fromaddress: {
        avatar: <Animal.Whale />,
        value: "stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
      Toaddress: {
        avatar: <Animal.Whale />,
        value: "adfadivkjduafueiquefajdshfuqeoiruaydfwegrasdfgaguetrif"
      },
      typeFrom: false,
      typeTo: false,
      tokenAmount: {
        vale: 988291,
        percent: 40,
      },
      value: {
        value: 4040,
        percent: 40,
      },
      walletprofiler: {
        avatar: <Animal.Whale />,
        ownerurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0",
        proflierurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
    },
    {
      id: "14",
      time: 21,
      Fromaddress: {
        avatar: <Animal.Whale />,
        value: "stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
      Toaddress: {
        avatar: <Animal.SwordFish />,
        value: "adfadivkjduafueiquefajdshfuqeoiruaydfwegrasdfgaguetrif"
      },
      typeFrom: false,
      typeTo: false,
      tokenAmount: {
        vale: 992019,
        percent: 10,
      },
      value: {
        value: 292819,
        percent: 40,
      },
      walletprofiler: {
        avatar: <Animal.Whale />,
        ownerurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0",
        proflierurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
    },
    {
      id: "15",
      time: 11,
      Fromaddress: {
        avatar: <Animal.Whale />,
        value: "stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
      Toaddress: {
        avatar: <Animal.SwordFish />,
        value: "adfadivkjduafueiquefajdshfuqeoiruaydfwegrasdfgaguetrif"
      },
      typeFrom: false,
      typeTo: false,
      tokenAmount: {
        vale: 98212,
        percent: 50,
      },
      value: {
        value: 66190,
        percent: 40,
      },
      walletprofiler: {
        avatar: <Animal.Whale />,
        ownerurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0",
        proflierurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
    },
    {
      id: "16",
      time: 7,
      Fromaddress: {
        avatar: <Animal.Whale />,
        value: "stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
      Toaddress: {
        avatar: <Animal.SwordFish />,
        value: "adfadivkjduafueiquefajdshfuqeoiruaydfwegrasdfgaguetrif"
      },
      typeFrom: false,
      typeTo: false,
      tokenAmount: {
        vale: 19921,
        percent: 70,
      },
      value: {
        value: 54290,
        percent: 84,
      },
      walletprofiler: {
        avatar: <Animal.Whale />,
        ownerurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0",
        proflierurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
    },
    {
      id: "17",
      time: 14,
      Fromaddress: {
        avatar: <Animal.Whale />,
        value: "stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
      Toaddress: {
        avatar: <Animal.SwordFish />,
        value: "adfadivkjduafueiquefajdshfuqeoiruaydfwegrasdfgaguetrif"
      },
      typeFrom: false,
      typeTo: false,
      tokenAmount: {
        vale: 1332,
        percent: 40,
      },
      value: {
        value: 40190,
        percent: 20,
      },
      walletprofiler: {
        avatar: <Animal.Whale />,
        ownerurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0",
        proflierurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
    },
    {
      id: "18",
      time: 7,
      Fromaddress: {
        avatar: <Animal.Whale />,
        value: "stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
      Toaddress: {
        avatar: <Animal.SwordFish />,
        value: "adfadivkjduafueiquefajdshfuqeoiruaydfwegrasdfgaguetrif"
      },
      typeFrom: false,
      typeTo: false,
      tokenAmount: {
        vale: 88817,
        percent: 10,
      },
      value: {
        value: 3392,
        percent: 13,
      },
      walletprofiler: {
        avatar: <Animal.Whale />,
        ownerurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0",
        proflierurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
    },
    {
      id: "19",
      time: 17,
      Fromaddress: {
        avatar: <Animal.Whale />,
        value: "stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
      Toaddress: {
        avatar: <Animal.SwordFish />,
        value: "adfadivkjduafueiquefajdshfuqeoiruaydfwegrasdfgaguetrif"
      },
      typeFrom: false,
      typeTo: false,
      tokenAmount: {
        vale: 22947,
        percent: 60,
      },
      value: {
        value: 39182,
        percent: 50,
      },
      walletprofiler: {
        avatar: <Animal.Whale />,
        ownerurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0",
        proflierurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
    },
    {
      id: "20",
      time: 17,
      Fromaddress: {
        avatar: <Animal.Whale />,
        value: "stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
      Toaddress: {
        avatar: <Animal.SwordFish />,
        value: "adfadivkjduafueiquefajdshfuqeoiruaydfwegrasdfgaguetrif"
      },
      typeFrom: false,
      typeTo: false,
      tokenAmount: {
        vale: 22947,
        percent: 60,
      },
      value: {
        value: 39182,
        percent: 50,
      },
      walletprofiler: {
        avatar: <Animal.Whale />,
        ownerurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0",
        proflierurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
    },
  ],
  maxswapamount: 36326,
  totaldatanumber: 743,
};
