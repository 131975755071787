import ChartInformation from "../../components/Charts/ChartInformation"
import ChartPosition from "../../components/Charts/ChartPosition";
import ChartWatchList from "../../components/Charts/ChartWatchList";
import ChartBulish from "../../components/Charts/ChartBulish";
const ChartPoolInfo = () => {
  return (
    <>
      <ChartInformation />
      <ChartPosition />
      <ChartBulish />
      <ChartWatchList />
    </>
  )
}

export default ChartPoolInfo