import { Animal } from "../../../common/Icons";
import { SundaeSwap, SNEK, Tapply } from "../../../common/IMG/Images";
export const StakingDataSecond = {
  ItemValue: [
    {
      id: "0",
      like: false,
      time:5,
      address: {
        avatar: <Animal.Whale />,
        value: "stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
      protocol:{
        image:SundaeSwap,
        name:"SundaeSwap Delegation"
      },
      Token:{
        image:SNEK,
        name:"SNEK"
      },
      Type:"Deposit",
      type: false,
      amount: {
        value: 28.20,
        percent: 100
      },
      value: {
        value: 144,
        percent: 100
      },
      walletprofiler: {
        avatar: <Animal.Whale />,
        ownerurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0",
        proflierurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
    },
    {
      id: "1",
      like: false,
      time:5,
      address: {
        avatar: <Animal.Whale />,
        value: "stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
      protocol:{
        image:SundaeSwap,
        name:"SundaeSwap Delegation"
      },
      Token:{
        image:SNEK,
        name:"SNEK"
      },
      Type:"Deposit",
      type: false,
      amount: {
        value: 40.20,
        percent: 20
      },
      value: {
        value: 14,
        percent: 20
      },
      walletprofiler: {
        avatar: <Animal.Whale />,
        ownerurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0",
        proflierurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
    },
    {
      id: "2",
      like: false,
      time:5,
      address: {
        avatar: <Animal.Whale />,
        value: "stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
      protocol:{
        image:SundaeSwap,
        name:"SundaeSwap Delegation"
      },
      Token:{
        image:SNEK,
        name:"SNEK"
      },
      Type:"Deposit",
      type: false,
      amount: {
        value: 44.20,
        percent: 44
      },
      value: {
        value: 11,
        percent: 23
      },
      walletprofiler: {
        avatar: <Animal.Whale />,
        ownerurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0",
        proflierurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
    },
    {
      id: "3",
      like: false,
      time:5,
      address: {
        avatar: <Animal.Whale />,
        value: "stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
      protocol:{
        image:SundaeSwap,
        name:"SundaeSwap Delegation"
      },
      Token:{
        image:SNEK,
        name:"SNEK"
      },
      Type:"Deposit",
      type: false,
      amount: {
        value: 31.13,
        percent: 13
      },
      value: {
        value: 34,
        percent: 32
      },
      walletprofiler: {
        avatar: <Animal.Whale />,
        ownerurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0",
        proflierurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
    },
    {
      id: "4",
      like: false,
      time:5,
      address: {
        avatar: <Animal.Whale />,
        value: "stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
      protocol:{
        image:SundaeSwap,
        name:"SundaeSwap Delegation"
      },
      Token:{
        image:SNEK,
        name:"SNEK"
      },
      Type:"Withdraw",
      type: false,
      amount: {
        value: 50000,
        percent: 20
      },
      value: {
        value: 144,
        percent: 30
      },
      walletprofiler: {
        avatar: <Animal.Whale />,
        ownerurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0",
        proflierurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
    },
    {
      id: "5",
      like: false,
      time:5,
      address: {
        avatar: <Animal.Whale />,
        value: "stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
      protocol:{
        image:SundaeSwap,
        name:"SundaeSwap Delegation"
      },
      Token:{
        image:SNEK,
        name:"SNEK"
      },
      Type:"Deposit",
      type: false,
      amount: {
        value: 28.20,
        percent: 100
      },
      value: {
        value: 144,
        percent: 100
      },
      walletprofiler: {
        avatar: <Animal.Whale />,
        ownerurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0",
        proflierurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
    },
    {
      id: "6",
      like: false,
      time:5,
      address: {
        avatar: <Animal.Whale />,
        value: "stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
      protocol:{
        image:SundaeSwap,
        name:"SundaeSwap Delegation"
      },
      Token:{
        image:SNEK,
        name:"SNEK"
      },
      Type:"Deposit",
      type: false,
      amount: {
        value: 40.20,
        percent: 20
      },
      value: {
        value: 14,
        percent: 20
      },
      walletprofiler: {
        avatar: <Animal.Whale />,
        ownerurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0",
        proflierurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
    },
    {
      id: "7",
      like: false,
      time:5,
      address: {
        avatar: <Animal.Whale />,
        value: "stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
      protocol:{
        image:SundaeSwap,
        name:"SundaeSwap Delegation"
      },
      Token:{
        image:SNEK,
        name:"SNEK"
      },
      Type:"Deposit",
      type: false,
      amount: {
        value: 44.20,
        percent: 44
      },
      value: {
        value: 11,
        percent: 23
      },
      walletprofiler: {
        avatar: <Animal.Whale />,
        ownerurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0",
        proflierurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
    },
    {
      id: "8",
      like: false,
      time:5,
      address: {
        avatar: <Animal.Whale />,
        value: "stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
      protocol:{
        image:SundaeSwap,
        name:"SundaeSwap Delegation"
      },
      Token:{
        image:SNEK,
        name:"SNEK"
      },
      Type:"Deposit",
      type: false,
      amount: {
        value: 31.13,
        percent: 13
      },
      value: {
        value: 34,
        percent: 32
      },
      walletprofiler: {
        avatar: <Animal.Whale />,
        ownerurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0",
        proflierurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
    },
    {
      id: "9",
      like: false,
      time:5,
      address: {
        avatar: <Animal.Whale />,
        value: "stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
      protocol:{
        image:SundaeSwap,
        name:"SundaeSwap Delegation"
      },
      Token:{
        image:SNEK,
        name:"SNEK"
      },
      Type:"Withdraw",
      type: false,
      amount: {
        value: 50000,
        percent: 20
      },
      value: {
        value: 144,
        percent: 30
      },
      walletprofiler: {
        avatar: <Animal.Whale />,
        ownerurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0",
        proflierurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
    },
    {
      id: "10",
      like: false,
      time:5,
      address: {
        avatar: <Animal.Whale />,
        value: "stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
      protocol:{
        image:SundaeSwap,
        name:"SundaeSwap Delegation"
      },
      Token:{
        image:SNEK,
        name:"SNEK"
      },
      Type:"Deposit",
      type: false,
      amount: {
        value: 28.20,
        percent: 100
      },
      value: {
        value: 144,
        percent: 100
      },
      walletprofiler: {
        avatar: <Animal.Whale />,
        ownerurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0",
        proflierurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
    },
    {
      id: "11",
      like: false,
      time:5,
      address: {
        avatar: <Animal.Whale />,
        value: "stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
      protocol:{
        image:SundaeSwap,
        name:"SundaeSwap Delegation"
      },
      Token:{
        image:SNEK,
        name:"SNEK"
      },
      Type:"Deposit",
      type: false,
      amount: {
        value: 40.20,
        percent: 20
      },
      value: {
        value: 14,
        percent: 20
      },
      walletprofiler: {
        avatar: <Animal.Whale />,
        ownerurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0",
        proflierurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
    },
    {
      id: "12",
      like: false,
      time:5,
      address: {
        avatar: <Animal.Whale />,
        value: "stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
      protocol:{
        image:SundaeSwap,
        name:"SundaeSwap Delegation"
      },
      Token:{
        image:SNEK,
        name:"SNEK"
      },
      Type:"Deposit",
      type: false,
      amount: {
        value: 44.20,
        percent: 44
      },
      value: {
        value: 11,
        percent: 23
      },
      walletprofiler: {
        avatar: <Animal.Whale />,
        ownerurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0",
        proflierurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
    },
    {
      id: "13",
      like: false,
      time:5,
      address: {
        avatar: <Animal.Whale />,
        value: "stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
      protocol:{
        image:SundaeSwap,
        name:"SundaeSwap Delegation"
      },
      Token:{
        image:SNEK,
        name:"SNEK"
      },
      Type:"Deposit",
      type: false,
      amount: {
        value: 31.13,
        percent: 13
      },
      value: {
        value: 34,
        percent: 32
      },
      walletprofiler: {
        avatar: <Animal.Whale />,
        ownerurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0",
        proflierurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
    },
    {
      id: "14",
      like: false,
      time:5,
      address: {
        avatar: <Animal.Whale />,
        value: "stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
      protocol:{
        image:SundaeSwap,
        name:"SundaeSwap Delegation"
      },
      Token:{
        image:SNEK,
        name:"SNEK"
      },
      Type:"Withdraw",
      type: false,
      amount: {
        value: 50000,
        percent: 20
      },
      value: {
        value: 144,
        percent: 30
      },
      walletprofiler: {
        avatar: <Animal.Whale />,
        ownerurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0",
        proflierurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
    },
  ],
  maxswapamount: 36326,
  totaldatanumber: 743,
};
