import { Animal } from "../../../common/Icons";

export const StakingDataFirst = {
  ItemValue: [
    {
      id: "0",
      like: false,
      address: {
        avatar: <Animal.Whale />,
        value: "stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
      type: false,
      StakedBalance: {
        value: 28.20,
        percent: 100
      },
      Staked: {
        value: 144,
        percent: 100
      },
      UnStaked: {
        value: 1643268,
        percent: 100
      },
      Netflow: {
        value: 3286536,
        percent: 100
      },
      walletprofiler: {
        avatar: <Animal.Whale />,
        ownerurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0",
        proflierurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
    },
    {
      id: "1",
      like: false,
      address: {
        avatar: <Animal.Whale />,
        value: "stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
      type: false,
      StakedBalance: {
        value: 36271927.20,
        percent: 90
      },
      Staked: {
        value: 82313,
        percent: 50
      },
      UnStaked: {
        value: -1643268,
        percent: 40
      },
      Netflow: {
        value: 3346536,
        percent: 20
      },
      walletprofiler: {
        avatar: <Animal.Whale />,
        ownerurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0",
        proflierurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
    },
    {
      id: "2",
      like: false,
      address: {
        avatar: <Animal.Whale />,
        value: "stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
      type: false,
      StakedBalance: {
        value: 123374.20,
        percent: 70
      },
      Staked: {
        value: 1234,
        percent: 10
      },
      UnStaked: {
        value: 0,
        percent: 0
      },
      Netflow: {
        value: 3331123,
        percent: 13
      },
      walletprofiler: {
        avatar: <Animal.Whale />,
        ownerurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0",
        proflierurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
    },
    {
      id: "3",
      like: false,
      address: {
        avatar: <Animal.Whale />,
        value: "stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
      type: false,
      StakedBalance: {
        value: 123455.20,
        percent: 73
      },
      Staked: {
        value: 762112,
        percent: 30
      },
      UnStaked: {
        value: -123,
        percent: 20
      },
      Netflow: {
        value: 12345236,
        percent: 40
      },
      walletprofiler: {
        avatar: <Animal.Whale />,
        ownerurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0",
        proflierurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
    },
    {
      id: "4",
      like: false,
      address: {
        avatar: <Animal.Whale />,
        value: "stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
      type: false,
      StakedBalance: {
        value: 854321.20,
        percent: 73
      },
      Staked: {
        value: 6521972,
        percent: 28
      },
      UnStaked: {
        value: -629,
        percent: 60
      },
      Netflow: {
        value: 98712,
        percent: 40
      },
      walletprofiler: {
        avatar: <Animal.Whale />,
        ownerurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0",
        proflierurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
    },
    {
      id: "5",
      like: false,
      address: {
        avatar: <Animal.Whale />,
        value: "stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
      type: false,
      StakedBalance: {
        value: 28.20,
        percent: 100
      },
      Staked: {
        value: 144,
        percent: 100
      },
      UnStaked: {
        value: 1643268,
        percent: 100
      },
      Netflow: {
        value: 3286536,
        percent: 100
      },
      walletprofiler: {
        avatar: <Animal.Whale />,
        ownerurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0",
        proflierurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
    },
    {
      id: "6",
      like: false,
      address: {
        avatar: <Animal.Whale />,
        value: "stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
      type: false,
      StakedBalance: {
        value: 36271927.20,
        percent: 90
      },
      Staked: {
        value: 82313,
        percent: 50
      },
      UnStaked: {
        value: -1643268,
        percent: 40
      },
      Netflow: {
        value: 3346536,
        percent: 20
      },
      walletprofiler: {
        avatar: <Animal.Whale />,
        ownerurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0",
        proflierurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
    },
    {
      id: "7",
      like: false,
      address: {
        avatar: <Animal.Whale />,
        value: "stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
      type: false,
      StakedBalance: {
        value: 123374.20,
        percent: 70
      },
      Staked: {
        value: 1234,
        percent: 10
      },
      UnStaked: {
        value: 0,
        percent: 0
      },
      Netflow: {
        value: 3331123,
        percent: 13
      },
      walletprofiler: {
        avatar: <Animal.Whale />,
        ownerurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0",
        proflierurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
    },
    {
      id: "8",
      like: false,
      address: {
        avatar: <Animal.Whale />,
        value: "stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
      type: false,
      StakedBalance: {
        value: 123455.20,
        percent: 73
      },
      Staked: {
        value: 762112,
        percent: 30
      },
      UnStaked: {
        value: -123,
        percent: 20
      },
      Netflow: {
        value: 12345236,
        percent: 40
      },
      walletprofiler: {
        avatar: <Animal.Whale />,
        ownerurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0",
        proflierurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
    },
    {
      id: "9",
      like: false,
      address: {
        avatar: <Animal.Whale />,
        value: "stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
      type: false,
      StakedBalance: {
        value: 854321.20,
        percent: 73
      },
      Staked: {
        value: 6521972,
        percent: 28
      },
      UnStaked: {
        value: -629,
        percent: 60
      },
      Netflow: {
        value: 98712,
        percent: 40
      },
      walletprofiler: {
        avatar: <Animal.Whale />,
        ownerurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0",
        proflierurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
    },
    {
      id: "10",
      like: false,
      address: {
        avatar: <Animal.Whale />,
        value: "stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
      type: false,
      StakedBalance: {
        value: 28.20,
        percent: 100
      },
      Staked: {
        value: 144,
        percent: 100
      },
      UnStaked: {
        value: 1643268,
        percent: 100
      },
      Netflow: {
        value: 3286536,
        percent: 100
      },
      walletprofiler: {
        avatar: <Animal.Whale />,
        ownerurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0",
        proflierurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
    },
    {
      id: "11",
      like: false,
      address: {
        avatar: <Animal.Whale />,
        value: "stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
      type: false,
      StakedBalance: {
        value: 36271927.20,
        percent: 90
      },
      Staked: {
        value: 82313,
        percent: 50
      },
      UnStaked: {
        value: -1643268,
        percent: 40
      },
      Netflow: {
        value: 3346536,
        percent: 20
      },
      walletprofiler: {
        avatar: <Animal.Whale />,
        ownerurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0",
        proflierurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
    },
    {
      id: "12",
      like: false,
      address: {
        avatar: <Animal.Whale />,
        value: "stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
      type: false,
      StakedBalance: {
        value: 123374.20,
        percent: 70
      },
      Staked: {
        value: 1234,
        percent: 10
      },
      UnStaked: {
        value: 0,
        percent: 0
      },
      Netflow: {
        value: 3331123,
        percent: 13
      },
      walletprofiler: {
        avatar: <Animal.Whale />,
        ownerurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0",
        proflierurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
    },
    {
      id: "13",
      like: false,
      address: {
        avatar: <Animal.Whale />,
        value: "stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
      type: false,
      StakedBalance: {
        value: 123455.20,
        percent: 73
      },
      Staked: {
        value: 762112,
        percent: 30
      },
      UnStaked: {
        value: -123,
        percent: 20
      },
      Netflow: {
        value: 12345236,
        percent: 40
      },
      walletprofiler: {
        avatar: <Animal.Whale />,
        ownerurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0",
        proflierurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
    },
    {
      id: "14",
      like: false,
      address: {
        avatar: <Animal.Whale />,
        value: "stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
      type: false,
      StakedBalance: {
        value: 854321.20,
        percent: 73
      },
      Staked: {
        value: 6521972,
        percent: 28
      },
      UnStaked: {
        value: -629,
        percent: 60
      },
      Netflow: {
        value: 98712,
        percent: 40
      },
      walletprofiler: {
        avatar: <Animal.Whale />,
        ownerurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0",
        proflierurl: "https://www.riskwises.io/pro/profiler?address=stake1uxdytgqashzgrqnnyhk2q5me27lsfq8vxl56mfe3kpjqp5qg88gw0"
      },
    },
  ],
  maxswapamount: 36326,
  totaldatanumber: 743,
};
